import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import LoanOfficerHero from "../components/loanOfficerHero";
import MeetTheTeam from "../components/meetTheTeam";
import StackedInformationalSection from "../components/stackedInformationalSection";
import ReviewsCarousel from "../components/reviewsCarousel";
import ContactLoanOfficer from "../components/contactLoanOfficer";
import Footer from "../components/footer";
import Seo from "../components/seo";
import BannerWithCTA from "../components/bannerWithCTA";
import CompanyFacts from "../components/companyFacts";

const version = 3;
export const query = graphql`
query loanOfficerPage2($loanOfficerId: DirectusData_GraphQLStringOrFloat) {
  directus {
    aboutNovus {
      shortDescriptionTitle
      shortDescription
    }
    companyOverview {
      title
      description
      factOneTitle
      factOneDescription
      buttonOneLabel
      buttonOneURL
      buttonTwoLabel
      buttonTwoURL
      factOneIcon {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      factTwoTitle
      factTwoDescription
      factTwoIcon {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      factThreeTitle
            factThreeDescription
      factThreeIcon {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    loanOfficer(filter: {id: {_eq: $loanOfficerId}}) {
      firstName
      lastName
      title
      phoneNumber
      email
      nmlsNumber
      shortDescription
      overviewButtonOneText
      overviewButtonOneLink
      overviewButtonTwoText
      overviewButtonTwoLink
      meetTheTeamDescription
      applyLink
      continueApplicationLink
      currentBookingsLink
      facebook
      instagram
      linkedin
      twitter
      homeBot
      useLegacyUrl
      externalDomain
      domainUserName
      legacyUrl
      image {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      branch {
        name
        addressLineOne
        addressLineTwo
        city
        state
        postalCode
      }
      teamMembers {
        teamMember_id {
          status
          title
          firstName
          lastName
          headshot {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      reviews
    }
  }
}
`;

export default function LoanOfficerTemplate({ data }) {
  const officer = data.directus.loanOfficer[0];
  const companyOverview = data.directus.companyOverview;
  const fullName = `${officer.firstName} ${officer.lastName}`;
  const ogDescription = `Connect with ${fullName} today to find a home loan.`
  const headshotImage = officer?.image?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;

  const officerContact = {
    phoneNumbers: {
      office: officer.phoneNumber,
    },
    email: officer.email
  };

  const officerSocial = {
    facebook: officer.facebook,
    instagram: officer.instagram,
    twitter: officer.twitter,
    linkedin: officer.linkedin
  };

  return (
    <>
      <Helmet>
        <title>Novus Home Mortgage</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Nunito+Sans"
        />
      </Helmet>
      <Seo
        title={fullName}
        description={ogDescription}
        image={headshotImage} />
      <div className="layout">
        <Header
          phoneNumber={officer.phoneNumber}
          email={officer.email} 
          isLoanOfficerPage={true}
          homeBotLink={officer.homeBot}/>
        <main>
          <section className="main-content">
            <div className="container">
              <div>
                <LoanOfficerHero loanOfficer={officer}
                  version={version} />
                <StackedInformationalSection
                  title={`About ${officer.firstName}`}
                  description={officer.shortDescription}
                  buttonOneText={officer.overviewButtonOneText}
                  buttonOneLink={officer.overviewButtonOneLink}
                  buttonTwoText={officer.overviewButtonTwoText}
                  buttonTwoLink={officer.overviewButtonTwoLink}
                  version={version}
                  loanOfficer={officer} />
                <CompanyFacts
                  version={version} novusOverview={companyOverview} />
                <MeetTheTeam loanOfficer={officer}
                  version={version} />
                <ReviewsCarousel loanOfficer={officer}
                  version={version} />
                <BannerWithCTA loanOfficer={officer}
                  version={version} CTABtn="/calculator" /> 
                <ContactLoanOfficer loanOfficer={officer} version={version} />
              </div>
            </div>
          </section>
        </main>
        <Footer
          address={officer.branch}
          contact={officerContact}
          social={officerSocial} />
      </div>
    </>
  );
}

