// extracted by mini-css-extract-plugin
export var container = "companyFacts-module--container--DyZ3-";
export var sectionTitle = "companyFacts-module--sectionTitle--GD5FP";
export var greenLine = "companyFacts-module--greenLine--rtOoU";
export var overview = "companyFacts-module--overview--SNfut";
export var overviewDesc = "companyFacts-module--overviewDesc--kqwXl";
export var factsList = "companyFacts-module--factsList--4K9l3";
export var factContainer = "companyFacts-module--factContainer--d2cj5";
export var factIcon = "companyFacts-module--factIcon--ermWj";
export var factTitle = "companyFacts-module--factTitle--W5GEV";
export var factDetails = "companyFacts-module--factDetails--GGN-h";
export var buttonsWrap = "companyFacts-module--buttonsWrap--TcN3y";