import * as React from "react";
import { Link } from "gatsby";
import * as styles from './bannerWithCTA.module.scss';
import { RightArrow } from "../../../src/components/icons";
const BannerWithCTA = ({ loanOfficer, version, CTABtn }) => {

    return (
        <div className={styles.bannerBg}>
           <div className={styles.container}>
                <div className={styles.CtaHeadline}>Explore Our Loan Calculators</div>
                <div className={styles.CtaButtonWrap}>
                    <Link className={styles.arrowButton} to={CTABtn} state={{ loanOfficer }} target="_blank">
                        <span>
                            <RightArrow />
                        </span>
                    </Link>
                </div>
           </div>
        </div>

    );
}

export default BannerWithCTA;