import * as React from "react";
import RoundedButton from '../sub-components/roundedButton';
import * as styles from './companyFacts.module.scss';

const CompanyFacts = ({ version, novusOverview }) => {
    return (

        <div className={styles.container}>
            <div className={styles.overview}>
                <div className={styles.sectionTitle}>
                    <div>{novusOverview.title}</div>
                    <span className={styles.greenLine}></span>
                </div>
                <div className={styles.overviewDesc} dangerouslySetInnerHTML={{ __html: novusOverview.description }}></div>
            </div>
            <div className={styles.factsList}>
                <div className={styles.factContainer}>
                    <div className={styles.factIcon}><img src={novusOverview?.factOneIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.factTitle}>{novusOverview.factOneTitle}</div>
                    <div className={styles.factDetails}>{novusOverview.factOneDescription}</div>
                </div>
                <div className={styles.factContainer}>
                    <div className={styles.factIcon}><img src={novusOverview?.factTwoIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.factTitle}>{novusOverview.factTwoTitle}</div>
                    <div className={styles.factDetails}>{novusOverview.factTwoDescription}</div>
                </div>
                <div className={styles.factContainer}>
                    <div className={styles.factIcon}><img src={novusOverview?.factThreeIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} /></div>
                    <div className={styles.factTitle}>{novusOverview.factThreeTitle}</div>
                    <div className={styles.factDetails}>{novusOverview.factThreeDescription}</div>
                </div>
            </div>
            <div className={styles.buttonsWrap}>
                {novusOverview.buttonOneLabel && novusOverview.buttonOneURL &&
                    <RoundedButton color="blueToWhite" text={novusOverview.buttonOneLabel} destination={novusOverview.buttonOneURL} />
                }
                {novusOverview.buttonTwoLabel && novusOverview.buttonTwoURL &&
                    <RoundedButton color="transparentWithBlue" text={novusOverview.buttonTwoLabel} destination={novusOverview.buttonTwoURL} />
                }

            </div>
        </div>

    );
}

export default CompanyFacts;